import tw from "twin.macro";
import styled from "styled-components";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-icon.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Button = styled(PrimaryButtonBase)`
  ${tw`
    mt-auto bg-transparent inline-flex items-center pt-5 text-sm font-bold
    text-primary-300 leading-none
    transition duration-300
    hocus:text-primary-900
    hocus:bg-transparent
    focus:shadow-none
  `}

  .icon {
    ${tw`ml-2 w-4`}
  }
`

export const LinkButton = ({
  children,
  ...rest
}) => {
  return (
    <Button {...rest}>
      {children}
      <ArrowRightIcon className="icon" />
    </Button>
  );
};