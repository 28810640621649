import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

const Container = styled.div`
  ${tw`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none`}

  > div {
    ${tw`relative w-auto my-6 mx-auto max-w-3xl`}
  }
`;

const Content = styled.div`
  ${tw`border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none`}

  min-width: 40vw;
`;

const Header = styled.div`
  ${tw`flex items-start justify-between p-5 border-b border-solid border-purple-200 rounded-t`}

  h3 {
    ${tw`text-3xl font-semibold`}
  }

  button {
    /* ${tw`p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none`} */
    ${tw`p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none`}

    span {
      /* ${tw`bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none`} */
      ${tw`bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none`}
    }
  }
`;

const Body = styled.div`
  ${tw`relative p-6 flex-auto`}

  p {
    ${tw`my-4 text-gray-900 text-lg leading-relaxed`}
  }
`;

// const Footer = styled.div`
//   ${tw`flex items-center justify-end p-6 border-t border-solid border-purple-200 rounded-b`}
// `;

// const CancelButton = styled.button`
//   ${tw`
//     text-red-500 bg-transparent font-bold uppercase px-6 py-2 text-sm outline-none
//     mr-1 mb-1 ease-linear transition-all duration-150
//     hocus:px-8 py-3 rounded-full
//     hocus:bg-red-200
//     hocus:text-red-600
//     focus:outline-none
//   `}
// `;

// const ConfirmButton = styled.button`
//   ${tw`
//     px-8 py-3 rounded-full bg-primary-500 text-gray-100
//     font-bold uppercase text-sm shadow
//     mr-1 mb-1 ease-linear transition-all duration-150
//     hocus:bg-primary-700
//     hocus:text-gray-200
//     hover:shadow-lg outline-none
//     focus:shadow-outline
//     active:bg-primary-600
//   `}
// `;

const Opacity = styled.div`
  ${tw`opacity-25 fixed inset-0 z-40 bg-black`}
`;

export default function Modal({
  setShowModal,
  title = null,
  content = null,
  showModal = false,
}) {
  title = title ?? "Modal Title";
  content = content ?? "Modal Content";

  return (
    <>
      {showModal ? (
        <>
          <Container className='aqui'>
            <div>
              {/*content*/}
              <Content>
                {/*header*/}
                <Header>
                  <h3>{title}</h3>
                  <button onClick={() => setShowModal(false)} >
                    <span> × </span>
                  </button>
                </Header>
                {/*body*/}
                <Body>
                  <ul>
                    {content.map((item, index) =>
                      <li key={index}>{item}</li>
                    )}
                  </ul>
                </Body>
                {/*footer*/}
                {/* <Footer>
                  <CancelButton
                    type="button"
                  // onClick={() => setShowModal(false)}
                  >
                    Close
                  </CancelButton>
                  <ConfirmButton
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </ConfirmButton>
                </Footer> */}
              </Content>
            </div>
          </Container>
          <Opacity />
        </>
      ) : null}
    </>
  );
}