import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";

export const WARRANTY_CARDS = [
  {
    imageSrc: ShieldIconImage,
    title: "Segurança",
    description: 'Nós valorizamos a tua segurança e por isso utilizamos a tecnologia mais segura.'
  },
  {
    imageSrc: SupportIconImage,
    title: "Suporte 24/7",
    description: "Temos uma equipa de suporte ao cliente pronta para te ajudar a qualquer momento."
  },
  {
    imageSrc: CustomizeIconImage,
    title: "Personalização",
    description: "Desenvolvemos os projetos à tua medida e de acordo com as tuas necessidades."
  },
  {
    imageSrc: FastIconImage,
    title: "Rapidez",
    description: 'Connosco não ficas a ver navios, nós entregamos o teu projeto "a tempo e horas".'
  },
  {
    imageSrc: SimpleIconImage,
    title: "Facilidade",
    description: 'Não complicamos, nós simplificamos. Somos a solução para o teu problema.'
  },
  {
    imageSrc: ReliableIconImage,
    title: "Feedback",
    description: 'Ninguém é perfeito, o teu feedback ajuda-nos a melhorar e a crescer.'
  }
];