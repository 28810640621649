import DesignIconImage from "images/design2-icon.svg";
import CodingIconImage from "images/coding2-icon.svg";
import EventsIconImage from "images/events-icon.svg";
import SolutionsIconImage from "images/solutions-icon.svg";
import MarketingIconImage from "images/marketing-icon.svg";

export const SERVICE_CARDS = [
  {
    imageSrc: DesignIconImage,
    title: "Design & Creative",
    description: "A tua marca é a tua cara. Nós ajudamos-te a criar a tua identidade visual e a comunicar a tua mensagem de forma clara e eficaz.",
    list: [
      "Naming",
      "Logotipo",
      "Manual de marca",
      "Manual de identidade visual",
      "Branding",
      "Design digital/gráfico",
      "Design editorial",
      "Design publicitário",
      "Fotografia",
      "Vídeo",
      "Edição fotorgrafia/vídeo",
    ],
  },
  {
    imageSrc: CodingIconImage,
    title: "Web & Programação",
    description: "Dores de cabeça com o teu site? Nós ajudamos-te a criar e a manter o teu site, de forma a que possas concentrar-te no que realmente importa para o teu negócio.",
    list: [
      "Web Design",
      "Web Development - Websites",
      "Websites institucionais",
      "Websites educacionais",
      "Landings pages",
      "E-commerce - Lojas online",
      "Gestão e manutenção de websites/lojas online",
      "Gestão de alojamentos, registos e domínios",
      "Programação",
      "Apps mobile",
      "Migração de websites",
    ],
  },
  {
    imageSrc: MarketingIconImage,
    title: "Marketing",
    description: `Leva a tua marca "até ao infinito e mais além". Ajudamos-te a criar e a gerir as campanhas de marketing que precisas, desde a criação à implementação.`,
    list: [
      "E-mail marketing",
      "SMS marketing",
      "Whatsapp marketing",
      "Newsletters",
      "SEO & SEM",
      "Registo de marcas",
      "Brindes publicitários",
      "Gestão de redes sociais",
      "Facebook & Instagram Ads",
      "Google Ads",
      "Bing Ads",
    ],
  },
  {
    imageSrc: EventsIconImage,
    title: "Eventos",
    description: "Eventos não são só para grandes empresas, nem precisão de ser um bicho de sete cabeças.",
    list: [
      "Lançamento da marca",
      "Gestão de eventos",
      "Feiras e exposições",
      "Workshops/Formações",
      "Team building",
    ],
  },
  {
    imageSrc: SolutionsIconImage,
    title: "Soluções Informáticas",
    description: "Ajudamos-te a encontrar a solução informática que precisas para o teu negócio.",
    list: [
      "Equipamentos informáticos",
      "Equipamentos de escritório",
      "Serviços de manutenção",
      "Serviços de instalação",
      "Serviços de suporte",
    ],
  },
];