import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import Services from "components/features/DashedBorderSixFeatures";
import AboutUs from "components/features/TwoColSingleFeatureWithStats2";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
// import FAQ from "components/faqs/SingleCol.js";
// import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as ThinkIcon } from "feather-icons/dist/icons/message-circle.svg";
import { ReactComponent as DevelopIcon } from "feather-icons/dist/icons/edit-3.svg";
import { ReactComponent as FinnishIcon } from "feather-icons/dist/icons/star.svg";
import { ReactComponent as UsersIcon } from "feather-icons/dist/icons/users.svg";
import { ReactComponent as ImproveIcon } from "feather-icons/dist/icons/layers.svg";
import { ReactComponent as FeedbackIcon } from "feather-icons/dist/icons/thumbs-up.svg";
import Modal from 'components/modals/defaultModal';
import { WARRANTY_CARDS } from 'constants/warrantyCards';
import { SERVICE_CARDS } from 'constants/serviceCards';
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <>
      <AnimationRevealPage>
        <Hero roundedHeaderButton={true} />
        <div id='services'>
          <Services
            cards={SERVICE_CARDS}
            subheading={<Subheading>Serviços</Subheading>}
            description='Montar um projecto de sucesso não é fácil e requer muita dedicação. Por isso deixa-nos ajudar-te a dar visibilidade ao teu negócio!'
            // Temos a solução para o teu projecto.
            heading={
              <>
                Como te podemos <HighlightedText>Ajudar.</HighlightedText>
              </>
            }
          />
        </div>
        <Features
          cards={WARRANTY_CARDS}
          subheading={<Subheading>Garantias</Subheading>}
          description='Connosco nunca ficas a boiar na maionese. Garantimos que o teu projecto é entregue no prazo acordado e com a qualidade que mereces.'
          heading={
            <>
              O que <HighlightedText>Garantimos.</HighlightedText>
            </>
          }
        />
        <div id='about-us'>
          <AboutUs
            subheading={<Subheading>Sobre nós</Subheading>}
            heading={<> Quem <HighlightedText>Somos?</HighlightedText> </>}
            description="Somos uma equipa de jovens profissionais que se dedica a criar soluções digitais para empresas e particulares. Acreditamos que a tecnologia pode ser uma grande aliada para o crescimento de qualquer negócio e por isso queremos ajudar-te a dar visibilidade ao teu negócio."
            statistics={[]}
          />
        </div>
        <MainFeature2
          description={null}
          subheading={<Subheading>Processo</Subheading>}
          heading={<> Como <HighlightedText>Trabalhamos.</HighlightedText> </>}
          imageSrc={prototypeIllustrationImageSrc}
          showDecoratorBlob={false}
          features={[
            {
              Icon: UsersIcon,
              title: "1. Reunimos",
              description: "Nada melhor do que uma boa conversa para percebermos o que é que tu precisas.",
              iconContainerCss: tw`bg-purple-200 text-purple-700`
            },
            {
              Icon: ThinkIcon,
              title: "2. Pensamos",
              description: "Planeamos o teu projecto e definimos os passos a seguir.",
              iconContainerCss: tw`bg-purple-200 text-purple-700`
            },
            {
              Icon: DevelopIcon,
              title: "3. Desenvolvemos",
              description: "Damos forma às melhores ideias para o teu projecto.",
              iconContainerCss: tw`bg-purple-200 text-purple-700`
            },
            {
              Icon: FeedbackIcon,
              title: "4. Feedback",
              description: "Pedimos-te a tua opinião para garantir que estamos no caminho certo.",
              iconContainerCss: tw`bg-purple-200 text-purple-700`
            },
            {
              Icon: ImproveIcon,
              title: "5. Melhoramos",
              description: "Aperfeiçoamos o teu projecto para que fique perfeito.",
              iconContainerCss: tw`bg-purple-200 text-purple-700`
            },
            {
              Icon: FinnishIcon,
              title: "6. Concretizamos",
              description: "Finalizamos o teu projecto e entregamos o resultado final.",
              iconContainerCss: tw`bg-purple-200 text-purple-700`
            },
          ]}
        />
        {/* <FAQ
          subheading={<Subheading>FAQS</Subheading>}
          heading={
            <>
              You have <HighlightedText>Questions ?</HighlightedText>
            </>
          }
          faqs={[
            {
              question: "Are all the templates easily customizable ?",
              answer:
                "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
              question: "How long do you usually support an standalone template for ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
              question: "What kind of payment methods do you accept ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
              question: "Is there a subscribption service to get the latest templates ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
              question: "Are the templates compatible with the React ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            },
            {
              question: "Do you really support Internet Explorer 11 ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }
          ]}
        /> */}
        {/* <GetStarted /> */}
        <div id='contacts'>
          <ContactUsForm />
        </div>
        <Footer />
      </AnimationRevealPage>
      <Modal />
    </>
  );
}
