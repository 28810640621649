import React, { useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";
import { enqueueSnackbar } from 'notistack';

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "CONTACTOS",
  heading = <>Entra em contacto <span tw="text-primary-500">connosco.</span><wbr /></>,
  description = `Só tens de preencher o formulário e nós entraremos em contacto contigo o mais breve possível. Ou se preferires, podes enviar-nos um email para geral@buzzapy.com ou ligar para o 932 154 858.`,
  submitButtonText = "Enviar",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    subject: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // prevent the form from submitting normall
    let readyToSubmit = true;

    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(formData.email)) {
      enqueueSnackbar('Tens de meter um email válido', { variant: 'warning' })
      return;
    }

    if (!formData.name) {
      readyToSubmit = false;
      enqueueSnackbar('Tens de meter um nome', { variant: 'warning' })
    }
    if (!formData.subject) {
      readyToSubmit = false;
      enqueueSnackbar('Tens de meter um assunto', { variant: 'warning' })
    }
    if (!formData.message) {
      readyToSubmit = false;
      enqueueSnackbar('Tens de meter uma mensagem', { variant: 'warning' })
      return;
    }
    if (formData.message.length < 10) {
      readyToSubmit = false;
      enqueueSnackbar('A tua mensagem tem de ser mais longa.', { variant: 'warning' })
    }

    if (!readyToSubmit) return;

    fetch('https://buzzapy-db-api-tmp.herokuapp.com/api/contacts/message', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData),
    })
      .then(response => {
        enqueueSnackbar('Recebemos o teu contacto. Responderemos o mais breve possível', { variant: 'success' })
        setFormData({
          name: '',
          email: '',
          company: '',
          subject: '',
          message: '',
        });
      })
      .catch(error => {
        enqueueSnackbar('Opa! Algo de errado não está certo! Tenta novamente mais tarde.', { variant: 'error' })
      });
  }

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form>
              <Input
                required
                type="email"
                name="email"
                placeholder="O teu email"
                value={formData.email}
                onChange={handleChange}
              />
              <Input
                required
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Nome completo"
              />
              <Input
                type="text"
                name="company"
                onChange={handleChange}
                value={formData.company}
                placeholder="Empresa (opcional)"
              />
              <Input
                required
                type="text"
                name="subject"
                placeholder="Assunto"
                onChange={handleChange}
                value={formData.subject}
              />
              <Textarea
                required
                name="message"
                onChange={handleChange}
                value={formData.message}
                placeholder="A tua mensagem"
              />
              <SubmitButton type="button" onClick={handleSubmit}>{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
